










































































import {Action, State} from 'vuex-class'
import {Vue,Component} from 'vue-property-decorator'
import {ObtenerTroza,ObtenerTablon, TrozasQuery, PalletTablon} from '@/entidades/KTB/Planificacion/PlanificacionListado';
import { Planta } from '@/entidades/Maestro/Planta';
import Alerta from '@/entidades/Sistema/Alerta';
@Component({
    name: 'PlanificacionView',
    components:{
        'planificacionAdd' :() => import('@/components/KTB/Operacion/Planificacion/PlanificacionAdd.vue')
    }
})
export default class PlanificacionView extends Vue
{
     @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    listadoTrozas:Array<TrozasQuery> =[];
    listadoTablon:Array<TrozasQuery> =[];
    listadoTrozaSeleccionada:Array<TrozasQuery> =[];
    listadoTablonSeleccionada:Array<PalletTablon> =[];
     header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Hacienda",sortable:false,value:'haciendaName'},
            {text:"T2K",sortable:true,value:'t2K'},
            {text:"Volumen",sortable:true,value:'volumen'},                     
    ];

    headerDetalle:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"# Pallet",sortable:false,value:'palletId'},
            {text:"Espesor",sortable:true,value:'espesor'},
            {text:"Volumen",sortable:true,value:'volumen'},                     
    ];
    modal:boolean=false;
    componenteName:string ="";

    async limpiar()
    {
        this.modal =false;
        this.componenteName = "";
        this.listadoTrozas = (await ObtenerTroza(this.plantas[0].plantaId)).isResult;
        this.listadoTablon = (await ObtenerTablon(this.plantas[0].plantaId)).isResult;
    }
    abrirModal()
    {
        if(this.listadoTrozaSeleccionada.length <=0 && this.listadoTablonSeleccionada.length <=0)
        {   
            this.changeAlerta(new Alerta("No hay seleccionado, el volumen para planificar",true,"danger",3000));
            return;
        }
        //validamos que se de la misma hacienda
        if(this.listadoTrozaSeleccionada.length > 1)
        {
            this.listadoTrozaSeleccionada.filter((item,index) =>{
                if(this.listadoTrozaSeleccionada.indexOf(item) == index)
                {
                    return;
                }
            })
        }
        this.componenteName = "planificacionAdd";
        this.modal = true;
    }
    get propiedades()
    {
        if(this.componenteName = "planificacionAdd")
        {
            return {'tablon' : this.listadoTablonSeleccionada, 'trozas' : this.listadoTrozaSeleccionada};
        }
    }
    async mounted() 
    {
        this.listadoTrozas = (await ObtenerTroza(this.plantas[0].plantaId)).isResult;
        this.listadoTablon = (await ObtenerTablon(this.plantas[0].plantaId)).isResult;
    }
}
